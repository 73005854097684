// react
import React, { useMemo } from "react";

// third-party
import { Helmet } from "react-helmet-async";

// application
import shopApi from "../../api/shop";
import { useDeferredData, useProductColumns, useProductTabs } from "../../services/hooks";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockBanner from "../blocks/BlockBanner";
import BlockBrands from "../blocks/BlockBrands";
import BlockCategories from "../blocks/BlockCategories";
import BlockFeatures from "../blocks/BlockFeatures";
import BlockPosts from "../blocks/BlockPosts";
import BlockProductColumns from "../blocks/BlockProductColumns";
import BlockProducts from "../blocks/BlockProducts";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";

// data stubs
import categories from "../../data/shopBlockCategories";
import posts from "../../data/blogPosts";
import theme from "../../data/theme";

function HomePageOne(props) {
    
    /**
     * Featured products.
     */
    const featuredProducts = useProductTabs(
        useMemo(
            () => [
                { id: 1, name: "Control", categorySlug: "control-y-proteccion-electrica" },
                { id: 2, name: "Cables", categorySlug: "cables-electricos" },
                { id: 3, name: "Iluminación", categorySlug: "iluminacion" },
                { id: 4, name: "Herramientas", categorySlug: "herramientas" },
            ],
            []
        ),
        (tab) => shopApi.getPopularProducts({ limit: 8, category: tab.categorySlug })
    );
    /**
     * Bestsellers.
     */
    const bestsellers = useDeferredData(() => shopApi.getPopularProducts({ limit: 7 }), []);

    /**
     * Latest products. // recien llegados
     */
    const latestProducts = useProductTabs(
        useMemo(
            () => [
                { id: 1, name: "Electricos", categorySlug: "enchufes-y-tomas-industriales" },
                { id: 2, name: "Control", categorySlug: "control-y-proteccion-electrica" },
                { id: 3, name: "Iluminación", categorySlug: "iluminacion" },
                { id: 4, name: "Herramientas", categorySlug: "herramientas" },
            ],
            []
        ),
        (tab) => shopApi.getLatestProducts({ limit: 8, category: tab.categorySlug })
    );

    /**
     * Product columns. // mejor valorados, ofertas especiales, mas vendidos
     */
    const columns = useProductColumns(
        useMemo(
            () => [
                {
                    title: "Mejor Valorados",
                    source: () => shopApi.getTopRatedProducts({ limit: 3 }),
                },
                {
                    title: "Ofertas Especiales",
                    source: () => shopApi.getDiscountedProducts({ limit: 3 }),
                },
                {
                    title: "Más Vendidos",
                    source: () => shopApi.getPopularProducts({ limit: 3 }),
                },
            ],
            []
        )
    );

    return (
        <React.Fragment>
            <Helmet>
                <title>{`${theme.name}`}</title>
            </Helmet>

            {useMemo(
                () => (
                    <BlockSlideShow /* withDepartments */ />
                ),
                []
            )}

            {useMemo(
                () => (
                    <BlockFeatures />
                ),
                []
            )}
            
            {useMemo(
                () => (
                    <BlockProductsCarousel
                        title="Productos Destacados"
                        layout="grid-4"
                        products={featuredProducts.data}
                        loading={featuredProducts.isLoading}
                        groups={featuredProducts.tabs}
                        onGroupClick={featuredProducts.handleTabChange}
                    />
                ),
                [featuredProducts]
            )}

            {useMemo(
                () => (
                    <BlockBanner />
                ),
                []
            )}

            {useMemo(
                () => (
                    <BlockProducts
                        title="Más Vendidos"
                        layout="large-first"
                        featuredProduct={bestsellers.data[0]}
                        products={bestsellers.data.slice(1, 7)}
                    />
                ),
                [bestsellers.data]
            )}

            {/* {useMemo(() => (
                <BlockCategories
                    title="Popular Categories"
                    layout="classic"
                    categories={categories}
                />
            ), [])} */}

            {useMemo(
                () => (
                    <BlockProductsCarousel
                        title="Recien Llegados"
                        layout="horizontal"
                        rows={2}
                        products={latestProducts.data}
                        loading={latestProducts.isLoading}
                        groups={latestProducts.tabs}
                        onGroupClick={latestProducts.handleTabChange}
                    />
                ),
                [latestProducts]
            )}

            {/* {useMemo(() => <BlockPosts title="Latest News" layout="list-sm" posts={posts} />, [])} */}

            {/* {useMemo(() => <BlockBrands />, [])} */}
            

            {useMemo(
                () => (
                    <BlockProductColumns columns={columns} />
                ),
                [columns]
            )}
        </React.Fragment>
    );
}

export default HomePageOne;
