export default [
    {
        id: 1,
        title: 'Como reconocer un cable Indeco original de un imitación',
        image: 'images/posts/arti1.jpg',
        categories: ['Cables electricos'],
        date: 'Febrero 17, 2022',
        abstract: 'Existen muchas imitaciones de Indeco, esto es que debes saber para comprar un producto original'
    },
    {
        id: 2,
        title: 'Todo sobre cables eléctricos',
        image: 'images/posts/post-2.jpg',
        categories: ['Latest News'],
        date: 'September 5, 2019',
        abstract:'este es un ejemplo 2'
    },
    {
        id: 3,
        title: 'Como elegir un cable eléctrico para mi instalación',
        image: 'images/posts/post-3.jpg',
        categories: ['New Arrivals'],
        date: 'August 12, 2019',
        abstract:'este es un ejemplo 3'
    },
    {
        id: 4,
        title: '¿Como se debe dimensionar los interruptores termomagnéticos?',
        image: 'images/posts/post-4.jpg',
        categories: ['Special Offers'],
        date: 'Jule 30, 2019',
        abstract:'este es un ejemplo'
    },
    {
        id: 5,
        title: '¿Que es un interruptor diferencial y como funciona?',
        image: 'images/posts/post-5.jpg',
        categories: ['New Arrivals'],
        date: 'June 12, 2019',
    },
    {
        id: 6,
        title: '¿Que es un Variador de Frecuencia y para que sirve?',
        image: 'images/posts/post-6.jpg',
        categories: ['Special Offers'],
        date: 'May 21, 2019',
    },
    {
        id: 7,
        title: '¿Como programar un Variador de Velocidad?',
        image: 'images/posts/post-7.jpg',
        categories: ['Special Offers'],
        date: 'April 3, 2019',
    },
    {
        id: 8,
        title: '¿Cable NYY o N2XOH?',
        image: 'images/posts/post-8.jpg',
        categories: ['Latest News'],
        date: 'Mart 29, 2019',
    },
    {
        id: 9,
        title: 'Diferencia entre el cable LSOH y el cable NH',
        image: 'images/posts/post-9.jpg',
        categories: ['New Arrivals'],
        date: 'February 10, 2019',
    },
    {
        id: 10,
        title: '¿Que es un Arrancador Suave o Softstarter?',
        image: 'images/posts/post-10.jpg',
        categories: ['Special Offers'],
        date: 'January 1, 2019',
    },
    {
        id: 11,
        title: '¿Existe Variador de Frecuencia monofásico?',
        image: 'images/posts/post-10.jpg',
        categories: ['Special Offers'],
        date: 'January 1, 2019',
    },
];
