const brandsData = [
    { name: 'Elcope', slug: 'elcope', image: 'assets/images/logos/logo-1.png' },
    { name: 'Indeco', slug: 'indeco', image: 'assets/images/logos/logo-2.png' },
    { name: 'Bticino', slug: 'bticino', image: 'assets/images/logos/logo-3.png' },
    { name: 'Chint', slug: 'chint', image: 'assets/images/logos/logo-4.png' },
    { name: 'LS', slug: 'ls', image: 'assets/images/logos/logo-5.png' },
    { name: 'Total', slug: 'total', image: 'assets/images/logos/logo-6.png' },
    { name: 'Celsa', slug: 'celsa', image: 'assets/images/logos/logo-7.png' },
    { name: 'FSL', slug: 'fsl', image: 'assets/images/logos/logo-7.png' },
    { name: 'Opalux', slug: 'opalux', image: 'assets/images/logos/logo-7.png' },
    { name: 'Mennekes', slug: 'mennekes', image: 'assets/images/logos/logo-7.png' },
    { name: 'Leviton', slug: 'leviton', image: 'assets/images/logos/logo-7.png' },
    { name: 'Invertek', slug: 'invertek', image: 'assets/images/logos/logo-7.png' },
    { name: 'Siemens', slug: 'siemens', image: 'assets/images/logos/logo-7.png' },
    { name: 'Toshiba', slug: 'toshiba', image: 'assets/images/logos/logo-7.png' },
];

export default brandsData;
