export default [

    /* {
        title: 'Ofertas y Promociones',
        url: '',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Home 1', url: '/' },
                { title: 'Home 2', url: '/home-two' },
            ],
        },
    },
    {
        title: 'Iluminación',
        url: '',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Interior', url: '/' },
                { title: 'Exterior', url: '/home-two' },
            ],
        },
    },
    {
        title: 'SmartHome',
        url: '/',
        props: {
            external: true,
            target: '_blank',
        },
    }, */

    {
        title: 'Inicio',
        url: '/',
        /* submenu: {
            type: 'menu',
            menu: [
                { title: 'Home 1', url: '/' },
                { title: 'Home 2', url: '/home-two' },
            ],
        }, */
    },
    {
        title: 'Ofertas',
        url: '/ofertas',
        /* submenu: {
            type: 'megamenu',
            menu: {
                size: 'nl',
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Herramientas',
                                url: '',
                                links: [
                                    { title: 'Engravers', url: '' },
                                    { title: 'Wrenches', url: '' },
                                    { title: 'Wall Chaser', url: '' },
                                    { title: 'Pneumatic Tools', url: '' },
                                ],
                            },
                            {
                                title: 'Iluminación',
                                url: '',
                                links: [
                                    { title: 'Thread Cutting', url: '' },
                                    { title: 'Chip Blowers', url: '' },
                                    { title: 'Sharpening Machines', url: '' },
                                    { title: 'Pipe Cutters', url: '' },
                                    { title: 'Slotting machines', url: '' },
                                    { title: 'Lathes', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Hogar',
                                url: '',
                                links: [
                                    { title: 'Screwdrivers', url: '' },
                                    { title: 'Handsaws', url: '' },
                                    { title: 'Knives', url: '' },
                                    { title: 'Axes', url: '' },
                                    { title: 'Multitools', url: '' },
                                    { title: 'Paint Tools', url: '' },
                                ],
                            },
                            {
                                title: 'Garden Equipment',
                                url: '',
                                links: [
                                    { title: 'Motor Pumps', url: '' },
                                    { title: 'Chainsaws', url: '' },
                                    { title: 'Electric Saws', url: '' },
                                    { title: 'Brush Cutters', url: '' },
                                ],
                            },
                        ],
                    },
                ],
            },
        }, */
    },
    {
        title: 'Tienda',
        url: '/shop',
        /* submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'Shop Grid',
                    url: '/shop/category-grid-3-columns-sidebar',
                    submenu: [
                        { title: '3 Columns Sidebar', url: '/shop/category-grid-3-columns-sidebar' },
                        { title: '4 Columns Full', url: '/shop/category-grid-4-columns-full' },
                        { title: '5 Columns Full', url: '/shop/category-grid-5-columns-full' },
                    ],
                },
                { title: 'Shop List', url: '/shop/category-list' },
                { title: 'Shop Right Sidebar', url: '/shop/category-right-sidebar' },
                {
                    title: 'Product',
                    url: '/shop/product-standard',
                    submenu: [
                        { title: 'Product', url: '/shop/product-standard' },
                        { title: 'Product Alt', url: '/shop/product-columnar' },
                        { title: 'Product Sidebar', url: '/shop/product-sidebar' },
                    ],
                },
                { title: 'Cart', url: '/shop/cart' },
                { title: 'Checkout', url: '/shop/checkout' },
                { title: 'Order Success', url: '/shop/checkout/success' },
                { title: 'Wishlist', url: '/shop/wishlist' },
                { title: 'Compare', url: '/shop/compare' },
                { title: 'Track Order', url: '/shop/track-order' },
            ],
        }, */
    },
    {
        title: 'Marcas',
        url: '/',
        submenu: {
            type: 'menu',
            menu: [

                { title: 'Elcope', url: '/shop/catalog?filter_brand=elcope'},
                { title: 'Indeco', url: '/shop/catalog?filter_brand=indeco'},
                { title: 'Bticino', url: '/shop/catalog?filter_brand=bticino'},
                { title: 'Chint', url: '/shop/catalog?filter_brand=chint'},
                { title: 'LS', url: '/shop/catalog?filter_brand=ls'},
                { title: 'Total', url: '/shop/catalog?filter_brand=total'},
                { title: 'Celsa', url: '/shop/catalog?filter_brand=celsa'},
                { title: 'Opalux', url: '/shop/catalog?filter_brand=opalux'},
                { title: 'Invertek', url: '/shop/catalog?filter_brand=invertek'},
                { title: 'Mennekes', url: '/shop/catalog?filter_brand=mennekes'},
                { title: 'Siemens', url: '/shop/catalog?filter_brand=siemens'},
                { title: 'Toshiba', url: '/shop/catalog?filter_brand=toshiba'},
                
            ],
        },
    },
    {
        title: 'Blog',
        url: '/blog/category-list',
        /* submenu: {
            type: 'menu',
            menu: [
                { title: 'Blog Classic', url: '/blog/category-classic' },
                { title: 'Blog Grid', url: '/blog/category-grid' },
                { title: 'Blog List', url: '/blog/category-list' },
                { title: 'Blog Left Sidebar', url: '/blog/category-left-sidebar' },
                { title: 'Post Page', url: '/blog/post-classic' },
                { title: 'Post Without Sidebar', url: '/blog/post-full' },
            ],
        }, */
    },
    {
        title: 'Nosotros',
        url: '/site/about-us',
        submenu: {
            type: 'menu',
            menu: [
                { title: 'Acerca de', url: '/site/about-us' },
                { title: 'Contactenos', url: '/site/about-us' },
                /* { title: 'Contact Us Alt', url: '/site/contact-us-alt' },
                { title: '404', url: '/site/not-found' }, */
                /* { title: 'Términos y Condiciones', url: '/site/terms' },
                { title: 'Preguntas Frecuentes', url: '/site/faq' }, */
               /*  { title: 'Components', url: '/site/components' },
                { title: 'Typography', url: '/site/typography' }, */
            ],
        },
    },
    /* {
        title: 'Foro',
        url: 'https://themeforest.net/item/stroyka-tools-store-react-ecommerce-template/23909258',
        props: {
            external: true,
            target: '_blank',
        },
    }, */
];
