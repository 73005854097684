// react
import React from 'react';

// third-party
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { FaWhatsapp } from 'react-icons/fa';
import { IconContext } from "react-icons";

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// application
import NavPanel from './NavPanel';
import Search from './Search';
//import Topbar from './Topbar';
import { Mack } from '../../svg';

function Header(props) {
    const { layout } = props;
    let bannerSection;

    if (layout === 'default') {
        bannerSection = (
            <div className="site-header__middle container">
                {/* aqui se pone el logo */}
                <div className="site-header__logo">
                    <Link to="/"><Mack /></Link>
                </div>
                {/* Barra de busqueda */}
                <div className="site-header__search">
                    <Search context="header" />
                </div>
                {/*  info Contacto se cambia desde archivo en.json de i18n messges*/}
                <div className="site-header__phone">
                    <div className="site-header__phone-title">
                    <IconContext.Provider value={{ color: "#25d366", size:"2em" }}>
                            <FaWhatsapp/>
                    </IconContext.Provider>
                    </div>
                    <div className="site-header__phone-number">
                        <FormattedMessage id="header.phone" defaultMessage="986874327" />
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="site-header">
            {/* <Topbar /> */}
            {bannerSection}
            <div className="site-header__nav-panel">
                <NavPanel layout={layout} />
            </div>
        </div>
    );
}

Header.propTypes = {
    /** one of ['default', 'compact'] (default: 'default') */
    layout: PropTypes.oneOf(['default', 'compact']),
};

Header.defaultProps = {
    layout: 'default',
};

export default Header;
