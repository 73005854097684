export default [
    
    {
        title: 'Cables Eléctricos',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'lg',
                image: {
                    ltr: 'images/megamenu/cables1.jpg',
                    rtl: 'images/megamenu/megamenu-2-rtl.jpg',
                },
                columns: [
                    {
                        size: 4,
                        links: [
                            {
                                title: 'Edificaciónes y Construcción',
                                url: '/shop',
                                links: [
                                    { title: 'Cables TW-80', url: '' },
                                    { title: 'Cables THW-90', url: '' },
                                    { title: 'Cables NH-80', url: '' },
                                    { title: 'Cables NHX-90', url: '' },
                                ],
                            },
                            {
                                title: 'Puesta a Tierra',
                                url: '/shop',
                                links: [
                                    { title: 'Cables Desnudos', url: '' },
                                    { title: 'Cables de Tierra CPT', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 4,
                        links: [
                            {
                                title: 'Cableado Industrial',
                                url: '/shop',
                                links: [
                                    { title: 'Cables Vulcanizados', url: '' },
                                    { title: 'Cables de Soldar', url: '' },
                                    { title: 'Cables N2X0H', url: '' },
                                    { title: 'Cables de Baterias', url: '' },
                                    { title: 'Cables NYY', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 4,
                        links: [
                            {
                                title: 'Cable de Control',
                                url: '/shop',
                                links: [
                                    { title: 'Cable GPT', url: '' },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        title: 'Herramientas',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'lg',
                image: {
                    ltr: 'images/megamenu/amoladora.jpg',
                    rtl: 'images/megamenu/megamenu-1-rtl.jpg',
                },
                columns: [
                    {
                        size: 3,
                        links: [
                            {
                                title: 'Herramientas Electricas',
                                url: '/shop',
                                links: [
                                    { title: 'Taladros', url: '' },
                                    { title: 'Amoladoras', url: '' },
                                    { title: 'Pistola de Calor', url: '' },
                                    /* { title: 'Plumbing', url: '' },
                                    { title: 'Wall Chaser', url: '' },
                                    { title: 'Pneumatic Tools', url: '' },
                                    { title: 'Milling Cutters', url: '' }, */
                                ],
                            },
                            {
                                title: 'Herramientas Inalambricas',
                                url: '/shop',
                                links: [
                                    { title: 'Atornilladores', url: '' },
                                    { title: 'Amoladoras', url: '' },
                                    /* { title: 'Axes', url: '' },
                                    { title: 'Multitools', url: '' },
                                    { title: 'Paint Tools', url: '' }, */
                                ],
                            },
                            /* { title: 'Workbenches', url: '', links: [] },
                            { title: 'Presses', url: '', links: [] },
                            { title: 'Spray Guns', url: '', links: [] },
                            { title: 'Riveters', url: '', links: [] }, */
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            
                            {
                                title: 'Herramientas Manuales',
                                url: '/shop',
                                links: [
                                    { title: 'Destornilladores', url: '' },
                                    { title: 'Alicates', url: '' },
                                    { title: 'Cuters', url: '' },
                                    { title: 'Brocas', url: '' },
                                ],
                            },
                        ],
                    },
                    {
                        size: 3,
                        links: [
                            {
                                title: 'Maquinarias',
                                url: '/shop',
                                links: [
                                    { title: 'Maquinas de Soldar', url: '' },
                                    /*{ title: 'Accesorios', url: '' },
                                    { title: 'Sharpening Machines', url: '' },
                                    { title: 'Pipe Cutters', url: '' },
                                    { title: 'Slotting machines', url: '' },
                                    { title: 'Lathes', url: '' }, */
                                ],
                            },
                        ],
                    },
                    /* {
                        size: 3,
                        links: [
                            {
                                title: 'Instruments',
                                url: '',
                                links: [
                                    { title: 'Welding Equipment', url: '' },
                                    { title: 'Power Tools', url: '' },
                                    { title: 'Hand Tools', url: '' },
                                    { title: 'Measuring Tool', url: '' },
                                ],
                            },
                        ],
                    }, */
                ],
            },
        },
    },
    {
        title: 'Iluminación',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'lg',
                image: {
                    ltr: 'images/megamenu/Iluminacion.jpg',
                    rtl: 'images/megamenu/megamenu-3-rtl.jpg',
                },
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Luminarias Interiores',
                                url: '/shop',
                                links: [
                                    { title: 'Focos LED', url: '' },
                                    { title: 'Fluorescentes LED', url: '' },
                                    { title: 'Dicroicos LED', url: '' },
                                    { title: 'Paneles LED', url: '' },
                                    { title: 'Downlight', url: '' },
                                    { title: 'High Bay', url: '' },
                                ],
                            },
                            {
                                title: 'Luminarias Exteriores',
                                url: '/shop',
                                links: [
                                    { title: 'Reflectores LED', url: '' },
                                    { title: 'Reflectores Solares LED', url: '' },
                                    /* { title: 'Electric Saws', url: '' },
                                    { title: 'Brush Cutters', url: '' }, */
                                ],
                            },
                        ],
                    },
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Luminarias de Emergencia',
                                url: '/shop',
                                links: [
                                    { title: 'Luces de Emergencia', url: '' },
                                    { title: 'Linternas', url: '' },
                                    /* { title: 'Hand Tools', url: '' },
                                    { title: 'Measuring Tool', url: '' }, */
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    {
        title: 'Control y Proteccion Eléctrica',
        url: '',
        submenu: {
            type: 'megamenu',
            menu: {
                size: 'lg',
                /* size: 'sm', */
                image: {
                    ltr: 'images/megamenu/bkn.jpg',
                },
                columns: [
                    {
                        size: 6,
                        links: [
                            {
                                title: 'Residencial',
                                url: '/shop',
                                links: [
                                    { title: 'Interruptores Termomagneticos', url: '' },
                                    { title: 'Interruptores Diferenciales', url: '' },
                                    { title: 'Relojes Horarios', url: '' },
                                    { title: 'Tableros Electricos', url: '' },
                                    { title: 'Medidores Digitales', url: '' },
                                    /* { title: 'Paint Tools', url: '' }, */
                                ],
                            },
                            {
                                title: 'Industrial',
                                url: '/shop',
                                links: [
                                    { title: 'Variadores de Velocidad', url: '' },
                                    { title: 'Contactores', url: '' },
                                    { title: 'Reles Termicos', url: '' },
                                    { title: 'Arrancadores Suaves', url: '' },
                                    { title: 'Reles de Proteccion', url: '' },
                                ],
                            },
                        ],
                    },
                ],
            },
        },
    },
    /* {
        title: 'Electrical',
        url: '',
        submenu: {
            type: 'menu',
            menu: [
                {
                    title: 'Soldering Equipment',
                    url: '',
                    submenu: [
                        { title: 'Soldering Station', url: '' },
                        { title: 'Soldering Dryers', url: '' },
                        { title: 'Gas Soldering Iron', url: '' },
                        { title: 'Electric Soldering Iron', url: '' },
                    ],
                },
                { title: 'Light Bulbs', url: '' },
                { title: 'Batteries', url: '' },
                { title: 'Light Fixtures', url: '' },
                { title: 'Warm Floor', url: '' },
                { title: 'Generators', url: '' },
                { title: 'UPS', url: '' },
            ],
        },
    }, */
    { title: 'Enchufes y Tomas Industriales', url: '/shop', submenu: null },
    { title: 'Instrumentos de Medicion', url: '/shop', submenu: null },
    { title: 'Sistema de Puesta a Tierra', url: '/shop', submenu: null },
    /* { title: 'Redes', url: '', submenu: null },
    { title: 'Intercomunicadores', url: '', submenu: null }, */
    { title: 'Otros', url: '/shop', submenu: null },
];
