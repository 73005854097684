//informacion personal theme.
export default {
    name: 'ElectroMack',
    fullName: 'Distribuidor de materiales eléctricos de uso residencial, comercial e industrial',
    url: '',
    author: {
        name: 'Diego Zuni',
        profile_url: '',
    },
    contacts: {
        address: 'Cooperativa Universitaria G-1 Dpto 4A',
        email: 'electromackperu@gmail.com',
        phone: '993092373 - 986874327',
    },
};
