import { makeIdGenerator } from "../utils";

//prueba firebase
/* import { collection, getDocs } from "firebase/firestore";
import db from "../../firebase/firebaseConfig";

let categoriesDef = []
const getData = async () => {
    const categoriesDef = await getDocs(collection(db, "categorias"));
    //console.log(categoriesDef.docs.map(doc => doc.data()));
};
getData(); */


//console.log(categoriesDef)
/* hasta aqui prueba firebase*/
const getId = makeIdGenerator();

  const categoriesDef = [
    {
        name: "Cables Eléctricos",
        slug: "cables-electricos",
        items: 89,
    },

    {
        name: "Control y Protección Eléctrica",
        slug: "control-y-proteccion-electrica",
        items: 421,
        /* children: [
            {
                name: 'Residencial',
                slug: 'residencial'
            },
            {
                name: 'Industrial',
                slug: 'industrial'
            },
        ], */
    },
    {
        name: "Enchufes y Tomas Industriales",
        slug: "enchufes-y-tomas-industriales",
        items: 182,
    },
    /* {
        name: 'Instrumentos de Medicion',
        slug: 'instrumentos-de-medicion',
        items: 15,
    },
    {
        name: 'Sistema de Puesta a Tierra',
        slug: 'sistema-de-puesta-a-tierra',
        items: 89,
    }, */

    //mas categorias
    /* {
        name: 'Health & Beauty',
        slug: 'health-beauty',
        items: 201,
    }, */
    {
        name: "Iluminación",
        slug: "iluminacion",
        items: 54,
        /* children: [
            {
                name: 'Luminarias Interiores',
                slug: 'luminarias-interiores',
            },
            {
                name: 'Luminarias Exteriores',
                slug: 'luminarias-exteriores',
            },
            {
                name: 'Luminarias de Emergencia',
                slug: 'luminarias-de-emergencia',
            }
        ], */
    },
    {
        name: "Herramientas",
        slug: "herramientas",
        items: 272,
        /* children: [
            {
                name: 'Herramientas Eléctricas',
                slug: 'herramientas-electricas',
                image: 'assets/images/categories/category-1.jpg',
                
            },
            {
                name: 'Herramientas Inalámbricas',
                slug: 'herramientas-inalambricas',
                image: 'assets/images/categories/category-2.jpg',
                
            },
            {
                name: 'Herramientas Manuales',
                slug: 'herramientas-manuales',
                image: 'assets/images/categories/category-3.jpg',
                
            },
            {
                name: 'Maquinarias',
                slug: 'maquinarias',
                image: 'assets/images/categories/category-4.jpg',
                
            },
            
        ], */
    },
];

function walkTree(defs, parent = null) {
    let list = [];
    const tree = defs.map((def) => {
        const category = {
            id: getId(),
            name: def.name,
            slug: def.slug,
            image: def.image || null,
            items: def.items || 0,
            customFields: {},
            parent,
            children: [],
        };

        const [childrenTree, childrenList] = walkTree(def.children || [], category);

        category.children = childrenTree;
        list = [...list, category, ...childrenList];
        return category;
    });

    return [tree, list];
}
  
export function prepareCategory(category, depth) {
    let children;
    if (depth && depth > 0) {
        children = category.children.map((x) => prepareCategory(x, depth - 1));
    }

    return JSON.parse(
        JSON.stringify({
            ...category,
            parent: category.parent ? prepareCategory(category.parent) : null,
            children,
        })
    );
}

export const [categoriesTreeData, categoriesListData] = walkTree(categoriesDef);

//export array
