import { makeIdGenerator } from '../utils';
import brandsData from './brands';
import { categoriesListData, prepareCategory } from './categories';

const getId = makeIdGenerator();

const attributesDef = [
    {
        name: 'Color',
        slug: 'color',
        values: [
            { name: 'White', slug: 'white' },
            { name: 'Silver', slug: 'silver' },
            { name: 'Light Gray', slug: 'light-gray' },
            { name: 'Gray', slug: 'gray' },
            { name: 'Dark Gray', slug: 'dark-gray' },
            { name: 'Coal', slug: 'coal' },
            { name: 'Black', slug: 'black' },
            { name: 'Red', slug: 'red' },
            { name: 'Orange', slug: 'orange' },
            { name: 'Yellow', slug: 'yellow' },
            { name: 'Pear Green', slug: 'pear-green' },
            { name: 'Green', slug: 'green' },
            { name: 'Emerald', slug: 'emerald' },
            { name: 'Shamrock', slug: 'shamrock' },
            { name: 'Shakespeare', slug: 'shakespeare' },
            { name: 'Blue', slug: 'blue' },
            { name: 'Dark Blue', slug: 'dark-blue' },
            { name: 'Violet', slug: 'violet' },
            { name: 'Purple', slug: 'purple' },
            { name: 'Cerise', slug: 'cerise' },
        ],
    },
    {
        name: 'Speed',
        slug: 'speed',
        values: [
            { name: '750 RPM', slug: '750-rpm' },
        ],
    },
    {
        name: 'Power Source',
        slug: 'power-source',
        values: [
            { name: 'Cordless-Electric', slug: 'cordless-electric' },
        ],
    },
    {
        name: 'Battery Cell Type',
        slug: 'battery-cell-type',
        values: [
            { name: 'Lithium', slug: 'lithium' },
        ],
    },
    {
        name: 'Voltage',
        slug: 'voltage',
        values: [
            { name: '20 Volts', slug: '20-volts' },
        ],
    },
    {
        name: 'Battery Capacity',
        slug: 'battery-capacity',
        values: [
            { name: '2 Ah', slug: '2-Ah' },
        ],
    },
];

const productsDef = [
    /* {
        slug: 'electric-planer-brandix-kl370090g-300-watts',
        name: 'Electric Planer Brandix KL370090G 300 Watts',
        price: 749,
        images: [
            'images/products/product-1.jpg',
            'images/products/product-1-1.jpg',
        ],
        badges: 'new',
        rating: 4,
        reviews: 0,
        availability: 'in-stock',
        brand: 'brandix',
        categories: ['screwdrivers'],
        attributes: [
            { slug: 'color', values: 'yellow' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'undefined-tool-iradix-dps3000sy-2700-watts',
        name: 'Undefined Tool IRadix DPS3000SY 2700 Watts',
        price: 1019,
        images: [
            'images/products/product-2.jpg',
            'images/products/product-2-1.jpg',
        ],
        badges: 'hot',
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        brand: 'zosch',
        categories: ['instruments'],
        attributes: [
            { slug: 'color', values: ['silver', 'cerise'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    }, */
    // PRODUCTOS DESTACADOS CABLES
    {
        slug: 'cable-tw-80-14awg',
        name: 'Cable TW 14 AWG INDECO - 100m',
        price: 148,
        images: [
            'images/products/TW.jpg',
            'images/products/TW14.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO TW-80 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-tw-80-12awg',
        name: 'Cable TW 12 AWG INDECO - 100m',
        price: 228,
        images: [
            'images/products/TW12.jpg',
            'images/products/TW.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-thw-90-14awg',
        name: 'Cable THW 14 AWG CELSA - 100m',
        price: 135,
        images: [
            'images/products/Celsa1.jpg',
            'images/products/Celsa2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'CELSA THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-energia-n2xoh-3-1x16mm2-0.6-1kv',
        name: 'Cable N2XOH 3-1x16mm2 ELCOPE',
        price: 36,
        images: [
            'images/products/n2xoh2.jpg',
            'images/products/n2xoh1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-thw-90-12awg',
        name: 'Cable THW 12 AWG CELSA - 100m',
        price: 208,
        images: [
            'images/products/Celsa2.jpg',
            'images/products/Celsa1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-tw-80-8awg',
        name: 'Cable TW 8 AWG INDECO - 100m',
        price: 600,
        images: [
            'images/products/THW8.jpg',
            'images/products/7HILOS.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-nh-80-(libre-de-halogeno)-2.5mm2',
        name: 'Cable libre de Halogeno NH-80 2.5 MM2 INDECO - 100m',
        price: 215,
        images: [
            'images/products/NHAM-VER.jpg',
            'images/products/NH2.5NEGRO.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-nmt-4x10awg-negro',
        name: 'Cable Vulcanizado 4x10 AWG ELCOPE - 100m',
        price: 1850,
        images: [
            'images/products/carrete.jpg',
            'images/products/vulcan-negro.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    
    //HASTA AQUI SON LOS 8 PRODUCTOS DE FEATUREDPRODUCTS
    {
        slug: 'cable-vulcanizado-nlt-2x16awg-gris',
        name: 'Cable Vulcanizado 2x16 AWG ELCOPE - 100m',
        price: 300,
        images: [
            'images/products/vulcan.jpg',
            'images/products/vulcan-2x-p.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-thw-14-awg-INDECO',
        name: 'Cable THW 14 AWG INDECO - 100m',
        price: 170,
        images: [
            'images/products/THW14ROJO.jpg',
            'images/products/THW14AZUL.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-thw-12-awg-INDECO',
        name: 'Cable THW 12 AWG INDECO - 100m',
        price: 260,
        images: [
            'images/products/THWROJO.jpg',
            'images/products/THW.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-thw-10-awg-INDECO',
        name: 'Cable THW 10 AWG INDECO - 100m',
        price: 400,
        images: [
            'images/products/TW.jpg',
            'images/products/THW.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-thw-8-awg-INDECO',
        name: 'Cable THW 8 AWG INDECO - 100m',
        price: 660,
        images: [
            'images/products/THW8.jpg',
            'images/products/THW.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    {
        slug: 'cable-thw-90-6awg',
        name: 'Cable THW 6 AWG INDECO - 100m',
        price: 1080,
        images: [
            'images/products/THW.jpg',
            'images/products/THW.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
        description:'INDECO THW-90 450/750V - Resistente al aceite, no propaga la Llama VW-1 HECHO EN PERU - Rollo 100 metros.'
    },
    
    {
        slug: 'cable-libre-de-halogeno-NH80-4-mm2-INDECO',
        name: 'Cable libre de Halogeno NH-80 4 MM2 INDECO - 100m',
        price: 315,
        images: [
            'images/products/NH6ROJO.jpg',
            'images/products/NHAM-VER.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-libre-de-halogeno-NH80-6-mm2-INDECO',
        name: 'Cable libre de Halogeno NH-80 6 MM2 INDECO - 100m',
        price: 490,
        images: [
            'images/products/NH2.5NEGRO.jpg',
            'images/products/NHAM-VER.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'indeco',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-2x18-awg-ELCOPE',
        name: 'Cable Vulcanizado 2x18 AWG ELCOPE - 100m',
        price: 215,
        images: [
            'images/products/vulcan.jpg',
            'images/products/vulcan-2x-p.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    
    {
        slug: 'cable-vulcanizado-2x14-awg-ELCOPE',
        name: 'Cable Vulcanizado 2x14 AWG ELCOPE - 100m',
        price: 430,
        images: [
            'images/products/vulcan.jpg',
            'images/products/vulcan-2x-p.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-2x12-awg-ELCOPE',
        name: 'Cable Vulcanizado 2x12 AWG ELCOPE - 100m',
        price: 630,
        images: [
            'images/products/vulcan.jpg',
            'images/products/vulcan-2x-p.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-2x10-awg-ELCOPE',
        name: 'Cable Vulcanizado 2x10 AWG ELCOPE - 100m',
        price: 950,
        images: [
            'images/products/vulcan-2x-n.jpg',
            'images/products/vulcan-2x-ne.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-3x18-awg-ELCOPE',
        name: 'Cable Vulcanizado 3x18 AWG ELCOPE - 100m',
        price: 290,
        images: [
            'images/products/cable3x.png',
            'images/products/vulcan.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-3x16-awg-ELCOPE',
        name: 'Cable Vulcanizado 3x16 AWG ELCOPE - 100m',
        price: 410,
        images: [
            'images/products/cable3x.png',
            'images/products/vulcan.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-3x14-awg-ELCOPE',
        name: 'Cable Vulcanizado 3x14 AWG ELCOPE - 100m',
        price: 610,
        images: [
            'images/products/vulcan.jpg',
            'images/products/cable3x.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-3x12-awg-ELCOPE',
        name: 'Cable Vulcanizado 3x12 AWG ELCOPE - 100m',
        price: 885,
        images: [
            'images/products/vulcan-3x-p.jpg',
            'images/products/vulcan-3x-p-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-3x10-awg-ELCOPE',
        name: 'Cable Vulcanizado 3x10 AWG ELCOPE - 100m',
        price: 1330,
        images: [
            'images/products/vulcan-3x-n.jpg',
            'images/products/vulcan-2x-n.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-3x8-awg-ELCOPE',
        name: 'Cable Vulcanizado 3x8 AWG ELCOPE - 100m',
        price: 2250,
        images: [
            'images/products/vulcan-3x-n.jpg',
            'images/products/vulcan-2x-n.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-4x16-awg-ELCOPE',
        name: 'Cable Vulcanizado 4x16 AWG ELCOPE - 100m',
        price: 530,
        images: [
            'images/products/vulcan-3x-p.jpg',
            'images/products/vulcan-4x-p-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-4x14-awg-ELCOPE',
        name: 'Cable Vulcanizado 4x14 AWG ELCOPE - 100m',
        price: 770,
        images: [
            'images/products/vulcan-3x-p.jpg',
            'images/products/vulcan-4x-p-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-4x12-awg-ELCOPE',
        name: 'Cable Vulcanizado 4x12 AWG ELCOPE - 100m',
        price: 1185,
        images: [
            'images/products/vulcan-3x-p.jpg',
            'images/products/vulcan-4x-p-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    
    {
        slug: 'cable-vulcanizado-4x8-awg-ELCOPE',
        name: 'Cable Vulcanizado 4x8 AWG ELCOPE - 100m',
        price: 2900,
        images: [
            'images/products/carrete.jpg',
            'images/products/vulcan-negro.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-vulcanizado-4x6-awg-ELCOPE',
        name: 'Cable Vulcanizado 4x6 AWG ELCOPE - 100m',
        price: 4250,
        images: [
            'images/products/carrete.jpg',
            'images/products/vulcan-negro.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-puesta-a-tierra-cpt-14-awg-ELCOPE',
        name: 'Cable para Puesta a Tierra CPT 14 AWG ELCOPE - 100m',
        price: 160,
        images: [
            'images/products/cpt.jpg',
            'images/products/cpt-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-puesta-a-tierra-cpt-12-awg-ELCOPE',
        name: 'Cable para Puesta a Tierra CPT 12 AWG ELCOPE - 100m',
        price: 260,
        images: [
            'images/products/cpt.jpg',
            'images/products/cpt-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-puesta-a-tierra-cpt-10-awg-ELCOPE',
        name: 'Cable para Puesta a Tierra CPT 10 AWG ELCOPE - 100m',
        price: 400,
        images: [
            'images/products/cpt.jpg',
            'images/products/cpt-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-puesta-a-tierra-cpt-8-awg-ELCOPE',
        name: 'Cable para Puesta a Tierra CPT 8 AWG ELCOPE - 100m',
        price: 600,
        images: [
            'images/products/cpt-2.jpg',
            'images/products/cpt-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    {
        slug: 'cable-puesta-a-tierra-cpt-6-awg-ELCOPE',
        name: 'Cable para Puesta a Tierra CPT 6 AWG ELCOPE - 100m',
        price: 1000,
        images: [
            'images/products/cpt-2.jpg',
            'images/products/cpt-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'elcope',
        categories: ['cables-electricos'],
    },
    
    // PRODUCTOS DE CONTROL  
    {
        slug: 'variador-de-frecuencia-trifasico-7.5HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 7.5HP CHINT',
        price: 1550,
        images: [
            'images/products/VFD-3.jpg',
            'images/products/VFD-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
        description: 'Variador de velocidad 7.5hp trifasico'
    },
    {
        slug: 'variador-de-frecuencia-monofasico-1HP-CHINT',
        name: 'Variador de Frecuencia Monofasico 220v 1HP CHINT',
        price: 1025,
        images: [
            'images/products/VFD-1.jpg',
            'images/products/VFD-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'arrancador-suave-30hp-22kw-42a-3p-380V-[NJR2-22D-380V]',
        name: 'Arrancador Suave Softstarter 30HP Trifasico 380V CHINT',
        price: 1950,
        images: [
            'images/products/soft1.jpg',
            'images/products/vfdsChint.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-50HP-TOSHIBA',
        name: 'Variador de Frecuencia Trifasico 380/480v 50HP TOSHIBA',
        price: 15000,
        images: [
            'images/products/VFD-toshiba-1.jpg',
            'images/products/VFD-toshiba-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'toshiba',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x100A-LS',
        name: 'Interruptor Termomagnetico 3x100A LS',
        price: 240,
        images: [
            'images/products/bkh3x100-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-2x40A-BTICINO',
        name: 'Interruptor Diferencial 2x40A BTICINO',
        price: 145,
        images: [
            'images/products/DIF2X40.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-7.5HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 7.5HP LS',
        price: 2270,
        images: [
            'images/products/VFD-LS-2.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-2HP-CHINT',
        name: 'Variador de Frecuencia Monofasico 220v 2HP CHINT',
        price: 870,
        images: [
            'images/products/VFD-2.jpg',
            'images/products/VFD-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-3HP-CHINT',
        name: 'Variador de Frecuencia Monofasico 220v 3HP CHINT',
        price: 1250,
        images: [
            'images/products/VFD-1.png',
            'images/products/VFD-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-1HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 1HP CHINT',
        price: 960,
        images: [
            'images/products/VFD-2.jpg',
            'images/products/VFD-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-2HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 2HP CHINT',
        price: 980,
        images: [
            'images/products/VFD-1.png',
            'images/products/VFD-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-3HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 3HP CHINT',
        price: 1100,
        images: [
            'images/products/VFD-2.jpg',
            'images/products/VFD-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-5HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 5HP CHINT',
        price: 1200,
        images: [
            'images/products/VFD-1.png',
            'images/products/VFD-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    
    {
        slug: 'variador-de-frecuencia-trifasico-10HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 10HP CHINT',
        price: 1450,
        images: [
            'images/products/VFD-3.jpg',
            'images/products/VFD-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-15HP-CHINT',
        name: 'Variador de Frecuencia Trifasico 380v 15HP CHINT',
        price: 2150,
        images: [
            'images/products/VFD-3.jpg',
            'images/products/VFD-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'chint',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-1HP-INVERTEK',
        name: 'Variador de Frecuencia Ent. y Sal. Monofasica 220v 1HP INVERTEK',
        price: 850,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-1.5HP-INVERTEK',
        name: 'Variador de Frecuencia Ent. y Sal. Monofasica 220v 1.5HP INVERTEK',
        price: 1025,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-1HP-INVERTEK',
        name: 'Variador de Frecuencia Monofasico 220v 1HP INVERTEK',
        price: 840,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-2HP-INVERTEK',
        name: 'Variador de Frecuencia Monofasico 220v 2HP INVERTEK',
        price: 980,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-monofasico-3HP-INVERTEK',
        name: 'Variador de Frecuencia Monofasico 220v 3HP INVERTEK',
        price: 1200,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-1HP-INVERTEK',
        name: 'Variador de Frecuencia Trifasico 380v 1HP INVERTEK',
        price: 1050,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-2HP-INVERTEK',
        name: 'Variador de Frecuencia Trifasico 380v 2HP INVERTEK',
        price: 1280,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-3HP-INVERTEK',
        name: 'Variador de Frecuencia Trifasico 380v 3HP INVERTEK',
        price: 1450,
        images: [
            'images/products/VFD-INVERT1.jpg',
            'images/products/VFD-INVERT2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'disponible',
        brand: 'invertek',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-1HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 1HP LS',
        price: 1050,
        images: [
            'images/products/VFD-LS-1.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-2HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 2HP LS',
        price: 1300,
        images: [
            'images/products/VFD-LS-1.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-3HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 3HP LS',
        price: 1450,
        images: [
            'images/products/VFD-LS-1.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-5HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 5HP LS',
        price: 1870,
        images: [
            'images/products/VFD-LS-3.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-10HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 10HP LS',
        price: 2700,
        images: [
            'images/products/VFD-LS-3.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-15HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 15HP LS',
        price: 3700,
        images: [
            'images/products/VFD-LS-2.jpg',
            'images/products/VFD-LS-4.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-20HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 20HP LS',
        price: 4600,
        images: [
            'images/products/VFD-h100-1.jpg',
            'images/products/VFD-h100-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-25HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 25HP LS',
        price: 5250,
        images: [
            'images/products/VFD-h100-1.jpg',
            'images/products/VFD-h100-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-30HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 30HP LS',
        price: 6300,
        images: [
            'images/products/VFD-h100-1.jpg',
            'images/products/VFD-h100-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-40HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 40HP LS',
        price: 9400,
        images: [
            'images/products/VFD-h100-1.jpg',
            'images/products/VFD-h100-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-50HP-LS',
        name: 'Variador de Frecuencia Trifasico 380/480v 50HP LS',
        price: 11500,
        images: [
            'images/products/VFD-h100-1.jpg',
            'images/products/VFD-h100-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-30HP-TOSHIBA',
        name: 'Variador de Frecuencia Trifasico 380/480v 30HP TOSHIBA',
        price: 11000,
        images: [
            'images/products/VFD-toshiba-1.jpg',
            'images/products/VFD-toshiba-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'toshiba',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'variador-de-frecuencia-trifasico-40HP-TOSHIBA',
        name: 'Variador de Frecuencia Trifasico 380/480v 40HP TOSHIBA',
        price: 12300,
        images: [
            'images/products/VFD-toshiba-1.jpg',
            'images/products/VFD-toshiba-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'toshiba',
        categories: ['control-y-proteccion-electrica'],
    },
    
    {
        slug: 'variador-de-frecuencia-trifasico-60HP-TOSHIBA',
        name: 'Variador de Frecuencia Trifasico 380/480v 60HP TOSHIBA',
        price: 18500,
        images: [
            'images/products/VFD-toshiba-2.jpg',
            'images/products/VFD-toshiba-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'toshiba',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'arrancador-suave-20HP-Siemens',
        name: 'Arrancador Suave 20HP SIEMENS',
        price: 990,
        images: [
            'images/products/3rw-1.jpg',
            'images/products/3rw-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'siemens',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'arrancador-suave-25HP-Siemens',
        name: 'Arrancador Suave 25HP SIEMENS',
        price: 1380,
        images: [
            'images/products/3rw-1.jpg',
            'images/products/3rw-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'siemens',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'arrancador-suave-30HP-Siemens',
        name: 'Arrancador Suave 30HP SIEMENS',
        price: 1580,
        images: [
            'images/products/3rw-1.jpg',
            'images/products/3rw-2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'consultar stock',
        brand: 'siemens',
        categories: ['control-y-proteccion-electrica'],
    },
    // 
    {
        slug: 'interruptor-diferencial-2x25A-BTICINO',
        name: 'Interruptor Diferencial 2x25A BTICINO',
        price: 120,
        images: [
            'images/products/DIF2X25.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    
    {
        slug: 'interruptor-termomagnetico-2x16A-BTICINO',
        name: 'Interruptor Termomagnetico 2x16A BTICINO',
        price: 38,
        images: [
            /* 'https://res.cloudinary.com/zunico/image/upload/v1614127605/b2yn7gqjmou8lfm3muhq.jpg', */
            'images/products/ITM2X16.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
        /* attributes: [
            { slug: 'color', values: 'orange' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ], */
    },
    {
        slug: 'interruptor-termomagnetico-2x20A-BTICINO',
        name: 'Interruptor Termomagnetico 2x20A BTICINO',
        price: 38,
        images: [
            'images/products/ITM2X20.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x25A-BTICINO',
        name: 'Interruptor Termomagnetico 2x25A BTICINO',
        price: 38,
        images: [
            'images/products/ITM2X25.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x32A-BTICINO',
        name: 'Interruptor Termomagnetico 2x32A BTICINO',
        price: 38,
        images: [
            'images/products/ITM2X32.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x40A-BTICINO',
        name: 'Interruptor Termomagnetico 2x40A BTICINO',
        price: 50,
        images: [
            'images/products/ITM2X40.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x50A-BTICINO',
        name: 'Interruptor Termomagnetico 2x50A BTICINO',
        price: 58,
        images: [
            'images/products/ITM2X50.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x63A-BTICINO',
        name: 'Interruptor Termomagnetico 2x63A BTICINO',
        price: 58,
        images: [
            'images/products/ITM2X63.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x16A-BTICINO',
        name: 'Interruptor Termomagnetico 3x16A BTICINO',
        price: 110,
        images: [
            'images/products/ITM3X16.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x20A-BTICINO',
        name: 'Interruptor Termomagnetico 3x20A BTICINO',
        price: 110,
        images: [
            'images/products/ITM3X20.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x25A-BTICINO',
        name: 'Interruptor Termomagnetico 3x25A BTICINO',
        price: 110,
        images: [
            'images/products/ITM3X25.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x32A-BTICINO',
        name: 'Interruptor Termomagnetico 3x32A BTICINO',
        price: 110,
        images: [
            'images/products/ITM3X32.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x40A-BTICINO',
        name: 'Interruptor Termomagnetico 3x40A BTICINO',
        price: 130,
        images: [
            'images/products/ITM3X40.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x50A-BTICINO',
        name: 'Interruptor Termomagnetico 3x50A BTICINO',
        price: 130,
        images: [
            'images/products/ITM3X50.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x63A-BTICINO',
        name: 'Interruptor Termomagnetico 3x63A BTICINO',
        price: 130,
        images: [
            'images/products/ITM3X63.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'bticino',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'taladro-de-impacto-1010w-TOTAL',
        name: 'Taladro de Impacto 1010w TOTAL',
        price: 160,
        images: [
            'images/products/TALADRO.jpg',
            'images/products/TALA2.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'total',
        categories: ['herramientas'],
    },
    {
        slug: 'taladro-atornillador-inalambrico-12v-con-percutor-TOTAL',
        name: 'Taladro Atornillador Inalambrico 12v con percutor TOTAL',
        price: 200,
        images: [
            'images/products/ATORC2.jpg',
            'images/products/ATORC.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'total',
        categories: ['herramientas'],
    },
    {
        slug: 'taladro-atornillador-inalambrico-20v-TOTAL',
        name: 'Taladro Atornillador Inalambrico 20v TOTAL',
        price: 250,
        images: [
            'images/products/ATORS.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'total',
        categories: ['herramientas'],
    },
    {
        slug: 'Maquina-de-Soldar-160-amp-220v-TOTAL',
        name: 'Maquina de Soldar 160A 220v TOTAL',
        price: 350,
        images: [
            'images/products/SOLD1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'total',
        categories: ['herramientas'],
    },
    {
        slug: 'pistola-de-calor-2000w-TOTAL',
        name: 'Pistola de Calor 2000w TOTAL',
        price: 110,
        images: [
            'images/products/PISTOLA.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'total',
        categories: ['herramientas'],
    },
    {
        slug: 'reflector-led-30w-220V-6500k-OPALUX',
        name: 'Reflector led 30w 220V 6500k OPALUX',
        price: 40,
        images: [
            'images/products/reflector30w.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'opalux',
        categories: ['iluminacion'],
    },
    {
        slug: 'reflector-led-50w-220V-6500k-OPALUX',
        name: 'Reflector led 50w 220V 6500k OPALUX',
        price: 60,
        images: [
            'images/products/reflector50w.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'opalux',
        categories: ['iluminacion'],
    },
    {
        slug: 'reflector-led-100w-220V-6500k-OPALUX',
        name: 'Reflector led 100w 220V 6500k OPALUX',
        price: 100,
        images: [
            'images/products/reflector100-1.png',
            'images/products/reflector100w.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'opalux',
        categories: ['iluminacion'],
    },
    {
        slug: 'reflector-led-150w-220V-6500k-OPALUX',
        name: 'Reflector led 150w 220V 6500k OPALUX',
        price: 180,
        images: [
            'images/products/reflector150-1.png',
            'images/products/reflector150w.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'opalux',
        categories: ['iluminacion'],
    },
    {
        slug: 'reflector-led-200w-220V-6500k-OPALUX',
        name: 'Reflector led 200w 220V 6500k OPALUX',
        price: 230,
        images: [
            'images/products/reflector200-1.png',
            'images/products/reflector200w.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'opalux',
        categories: ['iluminacion'],
    },
    {
        slug: 'reflector-led-300w-220V-6500k-OPALUX',
        name: 'Reflector led 300w 220V 6500k OPALUX',
        price: 350,
        images: [
            'images/products/reflector300w.jpg',
            'images/products/reflector300-1.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'opalux',
        categories: ['iluminacion'],
    },
    {
        slug: 'enchufe-industrial-2p+t-16a-220V-6h-MENNEKES',
        name: 'Enchufe Industrial 2P+T 16A 220V 6H MENNEKES',
        price: 18,
        images: [
            'images/products/enchufe2p+t16.jpg',
            'images/products/enchufe2p+t32.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'mennekes',
        categories: ['enchufes-y-tomas-industriales'],
    },
    {
        slug: 'tomacorriente-industrial-aereo-2p+t-16a-220V-6h-MENNEKES',
        name: 'Tomacorriente Industrial Aereo 2P+T 16A 220V 6H MENNEKES',
        price: 25,
        images: [
            'images/products/tomaaereo2p.jpg',
            'images/products/tomaaereo2p+T16.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'mennekes',
        categories: ['enchufes-y-tomas-industriales'],
    },
    {
        slug: 'tomacorriente-industrial-empotrar-2p+t-16a-220V-6h-MENNEKES',
        name: 'Tomacorriente Industrial Empotrable 2P+T 16A 220V 6H MENNEKES',
        price: 25,
        images: [
            'images/products/tomaemp2p+T16.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'mennekes',
        categories: ['enchufes-y-tomas-industriales'],
    },
    {
        slug: 'enchufe-industrial-3p+t-32a-400V-6h-MENNEKES',
        name: 'Enchufe Industrial 3P+T 32A 400V 6H MENNEKES',
        price: 35,
        images: [
            'images/products/enchufe3p+t32.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'mennekes',
        categories: ['enchufes-y-tomas-industriales'],
    },
    {
        slug: 'tomacorriente-industrial-aereo-3p+t-32a-400V-6h-MENNEKES',
        name: 'Tomacorriente Industrial Aereo 3P+T 32A 400V 6H MENNEKES',
        price: 38,
        images: [
            'images/products/tomaaereo3p+T16.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'mennekes',
        categories: ['enchufes-y-tomas-industriales'],
    },
    {
        slug: 'tomacorriente-industrial-empotrar-3p+t-32a-400V-6h-MENNEKES',
        name: 'Tomacorriente Industrial Empotrable 3P+T 32A 400V 6H MENNEKES',
        price: 38,
        images: [
            'images/products/tomaemp3p+T.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'mennekes',
        categories: ['enchufes-y-tomas-industriales'],
    },
    {
        slug: 'interruptor-diferencial-2x25A-LS',
        name: 'Interruptor Diferencial 2x25A LS',
        price: 75,
        images: [
            'images/products/dif2x25ls.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-2x40A-LS',
        name: 'Interruptor Diferencial 2x40A LS',
        price: 90,
        images: [
            'images/products/dif2x40ls.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-2x63A-LS',
        name: 'Interruptor Diferencial 2x63A LS',
        price: 145,
        images: [
            'images/products/dif2x63ls.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-4x25A-LS',
        name: 'Interruptor Diferencial 4x25A LS',
        price: 220,
        images: [
            'images/products/dif4x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-4x40A-LS',
        name: 'Interruptor Diferencial 4x40A LS',
        price: 240,
        images: [
            'images/products/dif4x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-4x63A-LS',
        name: 'Interruptor Diferencial 4x63A LS',
        price: 300,
        images: [
            'images/products/dif4x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-diferencial-4x40A-LS',
        name: 'Interruptor Diferencial 4x40A LS',
        price: 240,
        images: [
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x16A-LS',
        name: 'Interruptor Termomagnetico 2x16A LS',
        price: 24,
        images: [
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x20A-LS',
        name: 'Interruptor Termomagnetico 2x20A LS',
        price: 24,
        images: [
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x25A-LS',
        name: 'Interruptor Termomagnetico 2x25A LS',
        price: 24,
        images: [
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x32A-LS',
        name: 'Interruptor Termomagnetico 2x32A LS',
        price: 24,
        images: [
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x40A-LS',
        name: 'Interruptor Termomagnetico 2x40A LS',
        price: 30,
        images: [
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x50A-LS',
        name: 'Interruptor Termomagnetico 2x50A LS',
        price: 38,
        images: [
            'images/products/itm2x50LS.jpg',
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x63A-LS',
        name: 'Interruptor Termomagnetico 2x63A LS',
        price: 38,
        images: [
            'images/products/itm2x50LS.jpg',
            'images/products/bkn.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x80A-LS',
        name: 'Interruptor Termomagnetico 2x80A LS',
        price: 155,
        images: [
            'images/products/bkh2x80.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x100A-LS',
        name: 'Interruptor Termomagnetico 2x100A LS',
        price: 165,
        images: [
            'images/products/bkh2x80.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-2x125A-LS',
        name: 'Interruptor Termomagnetico 2x125A LS',
        price: 215,
        images: [
            'images/products/bkh2x125.png',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x16A-LS',
        name: 'Interruptor Termomagnetico 3x16A LS',
        price: 50,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x20A-LS',
        name: 'Interruptor Termomagnetico 3x20A LS',
        price: 50,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x25A-LS',
        name: 'Interruptor Termomagnetico 3x25A LS',
        price: 50,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x32A-LS',
        name: 'Interruptor Termomagnetico 3x32A LS',
        price: 50,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x40A-LS',
        name: 'Interruptor Termomagnetico 3x40A LS',
        price: 55,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x50A-LS',
        name: 'Interruptor Termomagnetico 3x50A LS',
        price: 60,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x63A-LS',
        name: 'Interruptor Termomagnetico 3x63A LS',
        price: 60,
        images: [
            'images/products/bkn3x.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'interruptor-termomagnetico-3x80A-LS',
        name: 'Interruptor Termomagnetico 3x80A LS',
        price: 220,
        images: [
            'images/products/bkh3x100-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    
    {
        slug: 'interruptor-termomagnetico-3x125A-LS',
        name: 'Interruptor Termomagnetico 3x125A LS',
        price: 280,
        images: [
            'images/products/bkh3x125.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'contactor-9a-bob-220v-LS',
        name: 'Contactor 9A bob. 220v LS',
        price: 45,
        images: [
            'images/products/mc9.jpg',
            'images/products/mc9-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    {
        slug: 'contactor-12a-bob-220v-LS',
        name: 'Contactor 12A bob. 220v LS',
        price: 50,
        images: [
            'images/products/mc9.jpg',
            'images/products/mc9-1.jpg',
        ],
        rating: 5,
        reviews: 0,
        availability: 'in-stock',
        brand: 'ls',
        categories: ['control-y-proteccion-electrica'],
    },
    /* {
        slug: 'drill-series-3-brandix-ksr4590pqs-1500-watts',
        name: 'Drill Series 3 Brandix KSR4590PQS 1500 Watts',
        price: 949,
        compareAtPrice: 1189,
        images: [
            'images/products/product-4.jpg',
            'images/products/product-4-1.jpg',
        ],
        badges: 'sale',
        rating: 3,
        reviews: 15,
        availability: 'in-stock',
        brand: 'brandix',
        categories: [],
        attributes: [
            { slug: 'color', values: 'white' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-router-power-tool-2017erxpk',
        name: 'Brandix Router Power Tool 2017ERXPK',
        price: 1700,
        images: [
            'images/products/product-5.jpg',
            'images/products/product-5-1.jpg',
        ],
        rating: 4,
        reviews: 2,
        availability: 'in-stock',
        brand: 'wakita',
        categories: [],
        attributes: [
            { slug: 'color', values: 'dark-blue' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-drilling-machine-dm2019kw4-4kw',
        name: 'Brandix Drilling Machine DM2019KW4 4kW',
        price: 3199,
        images: [
            'images/products/product-6.jpg',
            'images/products/product-6-1.jpg',
        ],
        rating: 3,
        reviews: 21,
        availability: 'in-stock',
        brand: 'wakita',
        categories: [],
        attributes: [
            { slug: 'color', values: 'orange' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-pliers',
        name: 'Brandix Pliers',
        price: 24,
        images: [
            'images/products/product-7.jpg',
            'images/products/product-7-1.jpg',
        ],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        brand: 'wevalt',
        categories: [],
        attributes: [
            { slug: 'color', values: 'red' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'water-hose-40cm',
        name: 'Water Hose 40cm',
        price: 15,
        images: [
            'images/products/product-8.jpg',
            'images/products/product-8-1.jpg',
        ],
        rating: 2,
        reviews: 5,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: ['pear-green', 'blue'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'spanner-wrench',
        name: 'Spanner Wrench',
        price: 19,
        images: [
            'images/products/product-9.jpg',
            'images/products/product-9-1.jpg',
        ],
        rating: 4,
        reviews: 34,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: 'green' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'water-tap',
        name: 'Water Tap',
        price: 15,
        images: [
            'images/products/product-10.jpg',
            'images/products/product-10-1.jpg',
        ],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: 'gray' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'hand-tool-kit',
        name: 'Hand Tool Kit',
        price: 149,
        images: [
            'images/products/product-11.jpg',
            'images/products/product-11-1.jpg',
        ],
        rating: 4,
        reviews: 7,
        availability: 'in-stock',
        brand: 'hammer',
        categories: [],
        attributes: [
            { slug: 'color', values: 'black' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'ash-s-chainsaw-3.5kw',
        name: 'Ash\'s Chainsaw 3.5kW',
        price: 666.99,
        images: [
            'images/products/product-12.jpg',
            'images/products/product-12-1.jpg',
        ],
        rating: 5,
        reviews: 17,
        availability: 'in-stock',
        brand: 'mitasia',
        categories: [],
        attributes: [
            { slug: 'color', values: 'violet' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-angle-grinder-kzx3890pqw',
        name: 'Brandix Angle Grinder KZX3890PQW',
        price: 649,
        images: [
            'images/products/product-13.jpg',
            'images/products/product-13-1.jpg',
        ],
        rating: 2,
        reviews: 8,
        availability: 'in-stock',
        brand: 'mitasia',
        categories: [],
        attributes: [
            { slug: 'color', values: 'purple' },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-air-compressor-deltakx500',
        name: 'Brandix Air Compressor DELTAKX500',
        price: 1800,
        images: [
            'images/products/product-14.jpg',
            'images/products/product-14-1.jpg',
        ],
        rating: 3,
        reviews: 14,
        availability: 'in-stock',
        brand: 'brandix',
        categories: [],
        attributes: [
            { slug: 'color', values: ['light-gray', 'emerald'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-electric-jigsaw-jig7000bq',
        name: 'Brandix Electric Jigsaw JIG7000BQ',
        price: 290,
        images: [
            'images/products/product-15.jpg',
            'images/products/product-15-1.jpg',
        ],
        rating: 2,
        reviews: 1,
        availability: 'in-stock',
        brand: 'brandix',
        categories: [],
        attributes: [
            { slug: 'color', values: ['coal', 'shamrock'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    },
    {
        slug: 'brandix-screwdriver-screw1500acc',
        name: 'Brandix Screwdriver SCREW1500ACC',
        price: 1499,
        images: [
            'images/products/product-16.jpg',
            'images/products/product-16-1.jpg',
            'images/products/product-16-2.jpg',
            'images/products/product-16-3.jpg',
            'images/products/product-16-4.jpg',
        ],
        rating: 5,
        reviews: 3,
        availability: 'in-stock',
        brand: 'metaggo',
        categories: [],
        attributes: [
            { slug: 'color', values: ['dark-gray', 'shakespeare'] },
            { slug: 'speed', values: '750-rpm', featured: true },
            { slug: 'power-source', values: 'cordless-electric', featured: true },
            { slug: 'battery-cell-type', values: 'lithium', featured: true },
            { slug: 'voltage', values: '20-volts', featured: true },
            { slug: 'battery-capacity', values: '2-Ah', featured: true },
        ],
    }, */
];

/**
*/

/* let productsDef = [];
const getData = async () => {
    productsDef = await getDocs(collection(db, "productos"));
    console.log(productsDef.docs.map((doc) => doc.data()));
    return productsDef.docs.map((doc) => doc.data());
};
productsDef = getData();
new Promise((resolve) => {
    setTimeout(() => {
        resolve(products.slice(0, limit));
    }, 500);
}); */
const productsData = productsDef.map((productDef) => {
    let badges = [];

    if (productDef.badges) {
        badges = typeof productDef.badges === 'string' ? [productDef.badges] : productDef.badges;
    }

    const categories = categoriesListData
        .filter((category) => productDef.categories.includes(category.slug))
        .map((category) => prepareCategory(category));
    
    const attributes = (productDef.attributes || []).map((productAttributeDef) => {
        const attributeDef = attributesDef.find((x) => x.slug === productAttributeDef.slug);

        if (!attributeDef) {
            return null;
        }

        let valuesDef = [];

        if (typeof productAttributeDef.values === 'string') {
            valuesDef = [productAttributeDef.values];
        } else if (productAttributeDef.values) {
            valuesDef = productAttributeDef.values;
        }

        const values = valuesDef.map((valueSlug) => {
            const valueDef = attributeDef.values.find((x) => x.slug === valueSlug);

            if (!valueDef) {
                return null;
            }

            return {
                ...valueDef,
                customFields: {},
            };
        }).filter((x) => x !== null);

        if (!values.length) {
            return null;
        }

        return {
            name: attributeDef.name,
            slug: attributeDef.slug,
            featured: !!productAttributeDef.featured,
            values,
            customFields: {},
        };
    }).filter((x) => x !== null);

    return {
        id: getId(),
        name: productDef.name,
        sku: '83690/32',
        slug: productDef.slug,
        description: productDef.description,
        price: productDef.price,
        priceSol: 1,
        compareAtPrice: productDef.compareAtPrice || null,
        images: productDef.images.slice(),
        badges: badges.slice(),
        rating: productDef.rating,
        reviews: productDef.reviews,
        availability: productDef.availability,
        brand: brandsData.find((x) => x.slug === productDef.brand) || null,
        categories,
        attributes,
        customFields: {},
    };
});

export default productsData;
